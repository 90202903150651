import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../components/seo'
import Layout from '../components/Layout'
import MarkdownContent from '../components/MarkdownContent'
import NewsSnippet from '../components/NewsSnippet'


import '../components/index.css'


export default ({ data }) => {
  const { frontmatter } = data.GetIndexPage
  return (
    <div>
      <SEO title="Strona Główna" />
      <Layout>
        <div className="index">
          <Img className="index__image--main" fluid={frontmatter.image.childImageSharp.fluid} alt="Index Page" />
          <h1>{frontmatter.title}</h1>
          <div className="index__description">
            <MarkdownContent content={frontmatter.description_part_1} className="index__description-text" />
          </div>
          <div className="index__languages">
            {data.GetLanguages.edges.map(edge => (
              <Link key={edge.node.id} className="index__languages--link" style={{ borderRadius: '1rem', backgroundImage: `url(${edge.node.frontmatter.picture.childImageSharp.fluid.src})`, backgroundSize: 'cover' }} to={edge.node.fields.slug}>
                <div key={edge.node.id} className="index__languages-item">
                  <p key={edge.node.id} className="index__languages-item--label">{edge.node.frontmatter.language}</p>
                </div>
              </Link>
            ))}
          </div>
          <div className="index__description">
            <MarkdownContent content={frontmatter.description_part_2} className="index__description-text" />
          </div>
          <div className="index__news">
            <div className="index__news-header">
              <div className="index__news-header-wave--top">
                <svg viewBox="0 0 1440 160">
                  <g>
                    <path fill="#fff" d="M0,0 L1440,0 C823,0 549,160 0,160 Z" />
                  </g>
                </svg>
              </div>
              <h1 className="index__news-header-text">Aktualności</h1>
              <div className="index__news-header-wave--bottom">
                <svg viewBox="0 0 1440 160">
                  <g>
                    <path fill="#fff" d="M0,160 L0,0 C549,0 823,158 1440,158 L1440,160 L0,160 Z" transform="matrix(-1 0 0 1 1440 0)" />
                  </g>
                </svg>
              </div>
            </div>
            <div className="index__news-feed">
              {data.GetLatestNews.edges.map(edge => (
                <NewsSnippet
                  key={edge.node.id}
                  linkDestination={edge.node.fields.slug}
                  image={edge.node.frontmatter.image}
                  imgAlt={edge.node.id}
                  title={edge.node.frontmatter.title}
                  shortDescription={edge.node.frontmatter.short_description}
                />
              ))}
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export const query = graphql`
  query {
    GetIndexPage: markdownRemark( frontmatter: { templateKey: { eq: "index-page" }}) {
      frontmatter {
        title
        description_part_1
        description_part_2
        image {
          childImageSharp {
            fluid(maxWidth: 800, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    GetLatestNews: allMarkdownRemark( sort: { order: DESC, fields: [frontmatter___date]}, limit: 3, filter: { frontmatter: { templateKey: { eq: "news-post" }}}) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 500, quality: 70) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            short_description
            date
          }
        }
      }
    }
    GetLanguages: allMarkdownRemark( sort: { order: ASC, fields: [frontmatter___language]}, filter: { frontmatter: { templateKey: { eq: "language-post" }}}) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            picture {
              childImageSharp {
                fluid(maxWidth: 400, quality: 70) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            language
          }
        }
      }
    }
  }
`
